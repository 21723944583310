<template>
  <div class="admin">
    <v-card>
      <v-card-title>
        Test Send Grid Mail
      </v-card-title>
      <v-card-text>
        This will send a test email from social@shoplocalmo.com to jesse@chaosandorder.dev.
      </v-card-text>
      <v-card-actions>
        <v-btn class="ma-5" @click="testSendGridMail">Send Test Email</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// import { App } from '../firebase/app'
// import { getFunctions } from 'firebase/functions'
import { testSendGridMail } from '../firebase/functions'

// const testSendGridMail = Functions.httpsCallable('testSendGridMail')
export default {
  name: 'Admin',
  methods: {
    async testSendGridMail() {
      await testSendGridMail();
      debugger
    }
  }    
}
</script>

<style scoped>

</style>